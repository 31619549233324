exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-app-community-mdx": () => import("./../../../src/pages/app/community.mdx" /* webpackChunkName: "component---src-pages-app-community-mdx" */),
  "component---src-pages-app-magic-email-link-mdx": () => import("./../../../src/pages/app/magic-email-link.mdx" /* webpackChunkName: "component---src-pages-app-magic-email-link-mdx" */),
  "component---src-pages-app-privacy-mdx": () => import("./../../../src/pages/app-privacy.mdx" /* webpackChunkName: "component---src-pages-app-privacy-mdx" */),
  "component---src-pages-app-terms-mdx": () => import("./../../../src/pages/app-terms.mdx" /* webpackChunkName: "component---src-pages-app-terms-mdx" */),
  "component---src-pages-imprint-mdx": () => import("./../../../src/pages/imprint.mdx" /* webpackChunkName: "component---src-pages-imprint-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-students-mdx": () => import("./../../../src/pages/students.mdx" /* webpackChunkName: "component---src-pages-students-mdx" */)
}

